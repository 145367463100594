@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-out-top {
  -webkit-animation: slide-out-top 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-top 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.slide-out-left {
  -webkit-animation: slide-out-left 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-left 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(4);
            transform: scale(4);
    opacity: 0;
  }
}

@keyframes ripple {
  to {
    -webkit-transform: scale(4);
            transform: scale(4);
    opacity: 0;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

html {
  scroll-behavior: smooth;
}

html,
body {
  font-family: "Poppins", sans-serif;
  color: #313131;
}

body {
  background: #161923;
  font-size: 0.85rem;
}

h1 {
  font-weight: 700;
  text-align: center;
  font-size: calc(1.3rem + 0.6vw);
}

h2 {
  font-weight: 600;
  text-align: center;
  font-size: 0.85rem;
  padding: 0;
  margin: 0;
  letter-spacing: 0px;
}

h3 {
  font-weight: 700;
}

h4 {
  font-size: 0.85rem;
  color: #adb9c5;
}

.order-6 {
  -webkit-order: 6 !important;
          order: 6 !important;
}

.order-7 {
  -webkit-order: 7 !important;
          order: 7 !important;
}

.order-8 {
  -webkit-order: 8 !important;
          order: 8 !important;
}

.order-9 {
  -webkit-order: 9 !important;
          order: 9 !important;
}

.order-10 {
  -webkit-order: 10 !important;
          order: 10 !important;
}

a {
  text-decoration: none;
}

.box {
  background: #272d45;
  border-radius: 3px;
  min-height: 4.25rem;
  max-height: 4.5rem;
  font-weight: 600;
  height: 100%;
  color: #adb9c5;
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  outline: 0;
  cursor: pointer;
}

.box i {
  font-size: 1.25rem;
  color: #adb9c5;
  position: absolute;
  right: 1rem;
}

.box img,
.box svg {
  max-height: 100%;
  max-width: 100%;
}

.border-button {
  border: solid 1px #313131;
}

span.ripple {
  position: absolute;
  /* The absolute position we mentioned earlier */
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: ripple 600ms linear;
          animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.3);
}

.closer {
  position: fixed;
  top: -6%;
  font-size: 1.5rem;
  z-index: 99;
  color: #e6e6e6;
}

#portrait {
  width: 8rem;
  height: 8rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: -6%;
  transition: all ease-in-out 250ms;
}

#portrait img {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: all ease-in-out 250ms;
  z-index: 5;
}

.modalBox {
  left: 0;
  background: white;
  top: 8%;
  min-height: 88%;
  z-index: 9;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.overlay {
  position: fixed;
  background: #262e38;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1;
  top: 0;
  opacity: 1;
}

.overlayBlack {
  position: fixed;
  background: #000000b0;
  width: 100%;
  height: 125%;
  left: 0;
  z-index: 2;
  top: -25%;
  transition: all ease-in-out 250ms;
}

.button {
  border-radius: 3px;
  text-align: center;
  padding: 1rem;
  font-weight: 700;
  color: #fff;
}

.call {
  background: #262e38;
  border: solid thin #262e38;
  display: block;
}

.vcf {
  font-weight: 400 !important;
  border: solid thin #313131;
  color: #313131;
}

.socialMedia {
  -webkit-justify-content: center;
          justify-content: center;
}

.socialMedia i {
  font-size: 1rem;
  color: #313131;
  opacity: 0.7;
  margin: 1rem;
}

.socialMedia i:hover {
  color: white;
}

.heroImg {
  width: 100%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  transition: all ease-in-out 250ms;
}

.heroImg img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all ease-in-out 250ms;
}

.thumbnail {
  width: 5.8rem;
  height: 5.8rem;
  transition: all ease-in-out 250ms;
}

.thumbnail img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all ease-in-out 250ms;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.7;
}

.thumbnail .activeImg {
  -webkit-filter: none !important;
          filter: none !important;
  opacity: 1;
}

.thumbnail:nth-child(3n + 3) {
  padding-right: 0 !important;
}

.heroFull {
  z-index: 999;
  position: relative;
}

.heroFull img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all ease-in-out 250ms;
}

.zIndex-1 {
  z-index: -1;
}

.video {
  position: absolute;
  left: 0;
}

.landingBg {
  width: 100%;
  height: 8rem;
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  background-size: cover;
}

.logoWrapper {
  margin-top: 4.1rem;
  transition: all ease-in-out 200ms;
}

.logoWrapper img {
  z-index: 1;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border: solid 6px white;
  background: white;
  width: 120px;
  height: 120px;
  z-index: 1;
  transition: all ease-in-out 200ms;
}

.socialMediaWrapper {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.socialMediaWrapper i {
  font-size: 1rem;
  color: #e6e6e6;
  opacity: 0.7;
  margin: 1rem;
}

.block {
  text-align: center;
  color: "Poppins", sans-serif;
}

.controll {
  position: fixed;
  min-height: 0;
  width: 100%;
}

.controll .leftBtn {
  position: absolute;
  width: 15%;
  height: 44%;
}

.white {
  background: white;
}

.wrapperMain {
  padding-top: 0.5rem;
}

.cta {
  background: #a5d723;
  border: 1px solid #a5d723;
  color: #fff;
  text-decoration: none;
}

.cta i {
  color: #fff;
}

.sponsoredImg,
.logoText {
  color: white;
  opacity: 0.3;
}

.boxlink {
  text-decoration: none;
}

.boxWrapper {
  height: 6rem;
}

#loading {
  font-size: 10rem;
}

.loader {
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 2em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loaderWrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: white;
  z-index: 9999;
}

.loader--hide {
  opacity: 0;
}

.bright-text {
  color: #adb9c5;
}

.bright-text i {
  color: #adb9c5;
}

.dark-text {
  color: #313131;
}

.dark-text i {
  color: #313131;
}

.profile_link {
  font-weight: 600;
  color: #272f39;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.slide-out-top {
  -webkit-animation: slide-out-top 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-top 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-delayed {
  -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s both;
  animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s both;
}

.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-in-ver-top-delayed {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
}

.fade-out {
  -webkit-animation: fade-out 0.2s ease-out both;
  animation: fade-out 0.2s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 1;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 1;
  }
}

.fullBG {
  height: 100%;
  background-position: center center;
}
/*# sourceMappingURL=main.css.map */
